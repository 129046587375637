<template>
  <v-card v-if="accountData != null" flat class="pa-3 mt-2">
    <v-card-text class="d-flex">
      <!-- upload photo -->
      <div>
        <avatar-image-component :edit="adminProfileStore.id == accountData.id" :avatarUrl="accountData.avatar" :user="accountData"></avatar-image-component>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        class="multi-col-validation mt-6"
        lazy-validation
      >
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="accountData.name"
              label="Όνομα"
              dense
              outlined
              required
              :rules="nameRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="accountData.email"
              label="E-mail"
              :disabled="accountData.id != 1 && userType !== 'student'"
              dense
              outlined
              required
              :rules="emailRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
            disabled
              v-model="accountData.role_id"
              dense
              outlined
              label="Ιδιότητα"
              :items="roles"
              item-text="role"
              item-value="value"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="accountData.status_id"
              :disabled="accountData.id == 1"
              dense
              outlined
              label="Κατάσταση"
              :items="statuses"
              item-text="status"
              item-value="value"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="accountData.tel_number"
              outlined
              dense
              label="Τηλέφωνο"
              :rules="phoneRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" v-if="userType == 'student'">
            <v-text-field
              v-model="accountData.student_identification"
              outlined
              dense
              label="Ταυτότητα μαθητή"
            ></v-text-field>
          </v-col>
          <v-col v-else-if="userType == 'school' || (userType == 'guardian' && accountData.role_id==4)">
            <v-text-field
              v-model="accountData.balance"
              :disabled="userType == 'guardian' && accountData.role_id==4"
              outlined
              dense
              label="Υπόλοιπο"
            ></v-text-field>
          </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.invoice_data_title"
                outlined
                dense
                label="Invoice Data Title"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.company_name"
                outlined
                dense
                label="Company Name"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.company_job"
                outlined
                dense
                label="Company Job"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.company_address"
                outlined
                dense
                label="Company Address"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.company_address_po_box"
                outlined
                dense
                label="Company Address PO Box"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.company_city"
                outlined
                dense
                label="Company City"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.company_country"
                outlined
                dense
                label="Company Country"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.company_vat"
                outlined
                dense
                label="Company VAT"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.company_gemi_number"
                outlined
                dense
                label="Company GEMI Number"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="userType == 'school'">
                <v-text-field
                v-model="accountData.tax_office"
                outlined
                dense
                label="Company Tax Office"
                :rules="nameRules"
                ></v-text-field>
            </v-col>

          <v-col cols="12">
            <v-btn color="primary" class="me-3 mt-4" @click="saveUser">
              Αποθήκευση αλλαγών
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              @click="ΠΙΣΩ"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>

import AvatarImageComponent from '../../../components/AvatarImageComponent.vue'
import { mdiAlertOutline, mdiCloudUploadOutline } from "@mdi/js";
import { adminProfileStore } from "@/utils/helpers";

export default {
  components: {
    AvatarImageComponent
  },
  setup() {
    return { adminProfileStore }
  },
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
    userType: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      valid: true,
      nameRules: [(v) => !!v || "Συμπληρώστε το όνομα"],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || '«Το email πρέπει να είναι έγκυρο',
      ],
      phoneRules: [
        v => /^(|\d)+$/.test(v) || '«Το τηλέφωνο πρέπει να είναι έγκυρο',
      ],
      statuses: [
        { status: "Ενεργό", value: 1 },
        //{ status: "Προς έγκριση", value: 2 },
        { status: "Σε παύση", value: 3 },
      ],
      roles: [
        { role: "Admin", value: 1},
        { role: "Σχολείο", value: 2 },
        { role: "Οδηγός", value: 3 },
        { role: "Γονέας", value: 4},
        { role: "Κηδεμόνας", value: 5},
        { role: "Μαθητής", value: 6}
      ],
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    saveUser() {
      if (!this.validate()) return;
      this.$emit("save-user-info");
    },
    cancel()
    {
      this.$router.go(-1);
    }
  },
};
</script>
